/**
 * Created on 5/20/2016.
 */
angular.module('roundcomb')
  .run([
    'roundcombConfig',
    'FormConfigDomain',
    async function (roundcombConfig,
                    FormConfigDomain) {

      // try {
      //   let profileConfig = await FormConfigDomain.getByCode(roundcombConfig.tenantProfileForm.FormCode);
      // } catch (error) {
      //   FormConfigDomain.create(roundcombConfig.tenantProfileForm);
      // }
    }
  ]);